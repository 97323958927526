<template>
  <div>
    <v-btn color="transparent" small @click="$router.go(-1)" elevation="0" class="mb-2">
      <v-icon small left>mdi-arrow-left</v-icon>
      {{ $t("buttons.back") }}
    </v-btn>
    <h1 class="main-title">
      {{ $t("new.taskForTransportation") }} № {{ taskId }}
    </h1>
    <div class="task-info">
      <v-row class="flex-lg-row flex-column-reverse">
        <v-col lg="8" cols="12">
          <div class="order-col">
            <different-fields class="order-field" v-for="(header, index) in TASKFIELDS" :key="index" :headerObj="header"
              v-model="TASK[header.value]" :order="TASK.order.id" />
          </div>
          <v-btn color="primary mt-4" block @click="save()" height="38" width="450">
            {{ $t("buttons.save") }}
          </v-btn>
        </v-col>

        <v-col class="pa-2 d-flex justify-center" lg="4" cols="12">
          <div class="qr-code">
            <span v-if="TASK.qr_code <= 0">QR-code</span>
            <img :src="TASK.qr_code" alt="" />
          </div>
        </v-col>
      </v-row>
    </div>
    <h1 class="main-title mb-8">{{ $t("new.flightsAndWeigh") }}</h1>
    <table-empty :list="TRIPTASK" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import tableEmpty from "@/components/TableEmpty";
import differentFields from "@/components/differentFields";

export default {
  components: {
    tableEmpty,
    differentFields,
  },
  data: () => ({
    taskId: "",
  }),
  computed: {
    ...mapGetters(["TASK", "TRIPTASK", "TASKFIELDS"]),
  },
  methods: {
    ...mapActions(["getTaskByID", "getTripTask", "editBookItem"]),
    backTask() {
      this.$router.push({ name: "taskTransport" });
    },
    save() {
      this.editBookItem({
        book: "taskEdit",
        id: `${this.TASK.id}/`,
        data: this.TASK,
      }).finally(() => {
        this.getTaskByID({ id: this.taskId });
      });
    },
  },
  mounted() {
    this.taskId = this.$route.params.userId;
    this.getTaskByID({ id: this.taskId });
    this.getTripTask({ id: this.taskId });
  },
};
</script>

<style lang="scss" >
.task-info {
  margin-bottom: 40px;
}

.qr-code {
  width: 145px;
  height: 145px;
  background: #f1f3f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-col {
  max-height: 450px;
  display: flex;
  flex-direction: column;
  columns: 2;
  flex-wrap: wrap;
  column-gap: 20px;
}

@media (max-width: 1200px) {
  .order-col {
    columns: 1;
    max-height: none;
  }

  .mobile {
    flex-direction: column-reverse !important;
  }

  .main-title {
    font-size: 20px;
  }

  .xs-hidden {
    display: none;
  }
}

.tarea.v-input--is-disabled {
  background-color: #f1f3f9;
  padding-left: 20px;
}

.order-field:nth-last-child(2) {
  display: none;
}
</style>